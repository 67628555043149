<template>
  <div class="about" style="text-align: center;">
    <img :src="logo_img" alt="Z-Flux" style="max-width: 1500px;" />
  </div>
</template>


<script>

export default {
  name: 'HomeView',
  data() {
    return {
      msg: 'Welcome to Z-Flux!',
      logo_img: require('@/assets/zflux.jpg'),
    };
  },
};

</script>